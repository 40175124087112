import axios from "axios";

const PFL = "http://localhost:8200/api/v1";
const PF = "https://hotelsystem-h0zg.onrender.com/api/v1";

export const PF_PROD = "https://hotel-system-prod-api.onrender.com/api/v1";

export const apiClient = axios.create({
  baseURL: `${PF_PROD}`,
});
