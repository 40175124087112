import React from "react";
import Logo from "components/UI/Logo/Logo";
import Footers from "components/Footer/Footer";
import FooterMenu from "./FooterMenu";

const Footer = () => {
  return (
    <Footers
      logo={
        <Logo
          withLink
          linkTo="/"
          src="/images/logo/logo_main.png"
          title="PandoraSwift."
        />
      }
      menu={<FooterMenu />}
      copyright={`Copyright @ ${new Date().getFullYear()} Pandora Networks (EA) Ltd.`}
    />
  );
};

export default Footer;
