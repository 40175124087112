import React from "react";
import { NavLink } from "react-router-dom";
import { Menu } from "antd";

import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  PRIVACY_PAGE,
  PRICING_PLAN_PAGE,
  AGENT_PROFILE_PAGE,
} from "settings/constant";

const navigations = [
  {
    label: <NavLink to={`${HOME_PAGE}`}>Hotels</NavLink>,
    key: "hotels",
  },
  {
    label: (
      <NavLink to={`https://agent.pandoraswift.com`}>Agents Platform</NavLink>
    ),
    key: "https://agent.pandoraswift.com",
  },
  // {
  //   label: <NavLink to={`${PRICING_PLAN_PAGE}`}>Pricing</NavLink>,
  //   key: 'pricing',
  // },
  {
    // label: <NavLink to={`${PRIVACY_PAGE}`}>Privacy</NavLink>,
    label: (
      <NavLink
        to={`https://drive.google.com/file/d/1VObo-p6VZgBtFUWLRpuDt4_88oMk9l9c/view`}
      >
        Privacy
      </NavLink>
    ),
    key: "privacy",
  },
  {
    // label: <NavLink to={`${PRIVACY_PAGE}`}>Privacy</NavLink>,
    label: (
      <NavLink
        to={`https://play.google.com/store/apps/details?id=com.pandoraswift`}
      >
        Mobile App
      </NavLink>
    ),
    key: "mobile app",
  },
  // {
  //   label: <NavLink to={`${AGENT_PROFILE_PAGE}`}>Agent</NavLink>,
  //   key: "agent",
  // },
];

const FooterMenu = () => {
  return <Menu items={navigations} />;
};

export default FooterMenu;
