import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from "themes/default.theme";
import GlobalStyles from "themes/global.style";
import AuthProvider from "context/AuthProvider";
import AppRoutes from "./router";
import { ConfigProvider } from "antd";
import "antd/dist/reset.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { zIndex } from "styled-system";
const queryClient = new QueryClient();

const App = () => (
  <>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider theme={{ hashed: false }}>
          <React.Fragment>
            <GlobalStyles />
            <BrowserRouter>
              <AuthProvider>
                <AppRoutes />
              </AuthProvider>
            </BrowserRouter>
          </React.Fragment>
        </ConfigProvider>
      </QueryClientProvider>
    </ThemeProvider>
    <ToastContainer
      position="top-center"
      autoClose={20000}
      style={{ zIndex: 9999999 }}
    />
  </>
);
const root = createRoot(document.getElementById("root"));
root.render(<App />);
