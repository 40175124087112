export function normalizeData(data) {
  let normalizedData = [];

  // Regular expression to match phone numbers in the described format
  const phoneRegex = /(?:\+?256)?\s*0?(\d{3})\s*(\d{3})\s*(\d{3})/;

  data.forEach((item) => {
    if (typeof item === "string") {
      // If the item is a string, check if it looks like a phone number
      if (item.match(phoneRegex)) {
        // Extract digits from the phone number
        const digits = item.replace(/\D/g, "");

        // Check if the number fully matches the regex pattern
        const match = digits.match(phoneRegex);

        if (match) {
          // Construct the normalized phone number
          let normalizedNumber = "256" + match.slice(1).join("");

          normalizedData.push(normalizedNumber);
        } else {
          // If the number partially matches, include it without normalization
          normalizedData.push(item.trim());
        }
      } else {
        // If it's not a phone number, treat it as a name and include it
        normalizedData.push(item.trim());
      }
    } else if (typeof item === "number") {
      // If it's a number, include it as is without any modification
      normalizedData.push(item);
    }
  });

  // Remove duplicates from the normalized data
  const uniqueNormalizedData = Array.from(new Set(normalizedData));

  return uniqueNormalizedData;
}
